$background: #ff9ef9;
$text-color: #e5effe;
$link_active: #fc4854;
$drop_active: #a52c34;

$navbar-height: 75px;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  outline: none;
  border: none;
  font-size: 20 px;
  font-weight: 100;
  font-family: poppins, sans-serif;
  color:$text-color;
}
section {
  background-color: $background;
}

.back_to_top{
  cursor: pointer;
  transition: 0.4s;
  &:hover{
    color: $link-active;
  }
}

.footer{
  background-color: $background;
  
  &{
    padding: 15px 0;
    color: #e5effe50;
    font-size: 0.7rem;
    text-align: center;
  }
}

@import "../components/navbar";
@import "../pages/home";
@import "../pages/about";
@import "../pages/projects";
@import "./mobileStyles.scss";