$image-size: 500px;

.project__container {
  padding-top: 10vh;
  background-color: $background;
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: 50px;
  align-items: center;
  justify-content: baseline;
}
.right {
  margin-top: 10px;
  margin-right: 50px;
}
.project__item {
  display: grid;
  grid-template-columns: $image-size auto;
  column-gap: 20px;
  overflow: hidden;

  &__even {
    display: grid;
    grid-template-columns: $image-size auto;
    column-gap: 20px;
    overflow: hidden;
    background-color: #FD45DA;
  }
}

.project__image {
  padding: 20px;
  left: 30px;
  width: $image-size;
  height: 300px;
  object-fit: cover;
}
.project__header {
  font-weight: 700;
  font-size: 1.5rem;
}
.project__description {
  padding-top: 3px;
}
.view__btn {
  text-decoration: none;
  padding: 10px;
  border: 2px $text-color solid;
  border-radius: 5px;
  transition: $transition-time;
  &:hover {
    color: $link-active;
    border-color: $link-active;
  }
}