$triangle_size: 3px;
$triangle_thickness: 2px;
$transition_time: 0.4s ease;

.nav__container__scrolled {
  transition: 0.4s ease;
  filter: drop-shadow(0 4px 5px #00000060);
  background-color: #ff9ef9;
  width: 100%;
  height: $navbar-height;
  padding: 20px;
  position: fixed;
}
.nav__container {
  transition: 0.4s ease;
  background-color: #ff9ef9;
  width: 100%;
  height: $navbar-height;
  padding: 20px;
  position: fixed;
}

.nav__header {
  & a {
    transition: $transition_time;
  }

  &:hover a {
    cursor: pointer;
    color: $link_active;
    transition: $transition_time;
  }
}
.nav__links {
  display: grid;
  grid-template-columns: auto 4rem 4.5rem 9.2rem 5.3rem;
  column-gap: 0;
}
.nav__item {
  font-size: 1rem;
  a {
    &:hover {
      cursor: pointer;
      color: $link_active;
      transition: $transition_time;
    }
    transition: $transition_time;
    text-decoration: none;
  }

  &__drop {
    margin: 0px 10px;
    font-size: 1rem;
  }
}

.dropdown {
  &__active {
    &:hover {
      cursor: pointer;
    }
    color: $link_active;
  }
  &__decoration {
    border: solid #e5effe;
    border-width: 0 $triangle_thickness $triangle_thickness 0;
    display: inline-block;
    padding: $triangle_size;
    margin-left: 10px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: $transition_time;

    &__active {
      border: solid $link_active;
      border-width: 0 $triangle_thickness $triangle_thickness 0;
      display: inline-block;
      padding: $triangle_size;
      margin-left: 10px;
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
      transition: $transition_time;
    }
  }
}
.dropdown__item {
  pointer-events: none;
  transition: $transition_time;
  transform: translateY(200px);
  opacity: 0;
  &__active {
    background-color: #e5effe;
    padding: 10px 0;
    margin-right: -35px;
    transition: $transition_time;
    opacity: 1;
    transform: translateY(0vh);
    & a {
      padding: 0 25px;
      text-decoration: none;
      color: $link_active;
      transition: $transition_time;
      &:hover {
        cursor: pointer;
        transition: $transition_time;
        color: $drop_active;
      }
    }
  }
}

.menu {
  display: none;
  top: 3vh;
  width: 31px;
  height: 21px;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}

.menu .bar {
  height: 3px;
  width: 100%;
  background-color: $text-color;
  border-radius: 10px;
}
