@media (max-width: 900px) {
  //navbar
  .nav__container {
    padding: 0;
  }
  .menu {
    display: flex;
    left: 48%;
  }
  .nav__item,
  .nav__header,
  .nav__item__drop {
    display: none;
  }
  .nav__item__burger,
  .nav__header__burger,
  .nav__item__drop__burger {
    & a {
      text-decoration: none;
      color: $text-color;
    }
    display: grid;
    width: 100vw;
    background-color: $background;
    grid-template-rows: repeat(1, 1fr);
    justify-content: center;
  }
  .nav__item__burger,
  .nav__item__drop__burger {
    padding: 5px 0;
  }

  .dropdown__decoration__active {
    border: solid #e5effe;
    border-width: 0 $triangle_thickness $triangle_thickness 0;
    display: inline-block;
    padding: $triangle_size;
  }
  .dropdown__item {
    display: none;
    opacity: 0;
    &__active {
      margin-left: -40px;
      background-color: $background;
      transition: $transition_time;
      text-align: center;
      & a {
        text-decoration: none;
        color: $text-color;
      }
    }
  }
  .nav__links {
    display: block;
    margin-top: 75px;
  }

  //About
  .grid__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 15vw;
  }
  .left {
    margin-left: -20px;
    overflow: hidden;
  }

  .blurb {
    margin: 0;
    color: $text-color;
    font-size: 1.5rem;
    font-weight: 700;
  }

  //Projects
  .project__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    overflow: hidden;
    padding-bottom: 40px;
    width: 100%;
    &__even {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-self: center;
      overflow: hidden;
      background-color: #FD45DA;
      padding-bottom: 40px;
      width: 100%;
    }
  }
  .project__image {
    overflow: hidden;
    padding: 20px;
    left: 30px;
    width: $image-size * 1.4;
    object-fit: cover;
  }
  .project__header {
    font-weight: 700;
    font-size: 1.5rem;
    margin-left: 6vw;
  }
  .project__description {
    padding-top: 3px;
    margin-left: 6vw;
  }
  .view__btn {
    margin-left: 6vw;
  }
}
@media (max-width: 500px) {
  //Projects
  .project__image {
    width: $image-size / 1.2;
    overflow: hidden;
  }

  //Hero
  .home__container {
    padding-bottom: 50vh;
  }
  .hero__header {
    font-size: 2rem;
  }

  .Typewriter__wrapper, .Typewriter__cursor {
    margin-top: 0;
    font-size: 0.7rem;
  }

  //About
  .grid__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;
    margin-top: -10vh;
  }
  .about__container {
    padding: 30vh 0;
    background-color: $background;
  }
  .left {
    margin: 0;
  }
  .blurb {
    margin: 0;
    padding-top: 20px;
    padding-left: 10vw;
    color: $text-color;
    font-size: 1rem;
  }
}
